import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import { withRouter } from "next/router";

const App = () => {
	useEffect(() => {
		if (typeof window !== undefined) {
			window.location.href = "https://www.octaplus.be";
		}
	}, []);

	return (
		<Container maxWidth="sm">
		</Container>
	);
};

App.getInitialProps = async ({ query, req }: any) => ({
	namespacesRequired: ["common"]
});

// @ts-ignore
export default withRouter(App);
